ion-col {
  border: 1px solid white;
  color: white;
  font-size: 0.71rem;
}

#demo-grid {
  height: 30rem;
}

#demo-grid ion-row {
  height: 50%;
}
